.articleh-wrapper img {
    display: block;
    width: 100%;
    border-radius: 10px 10px 0 0;
    max-height: 210px;
}

.articleh-wrapper {
    background-color: white;
    width: 84%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.articleh-description {
    padding: 10px
}

.articleh-header {
    margin: 0;
}