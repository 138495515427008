.side-bar-side-block {
  /* background-color: rgb(199, 199, 199); */
  /*  max-height: 1000px; */
  max-height: 450px;
 /*  min-height: 450px; */
  width: 100%;
  height: 100%;
  margin: 5px auto;
  margin-bottom: 20px;
}

.vk-banner {
  background: center center no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
}

.vk-banner-header {
  font-weight: bold;
  color: rgb(255, 255, 255);
  position: absolute;
  margin-left: 1rem;
  left: 0;
  bottom: 0;
  margin-bottom: 0.7rem;
  z-index: 1;
  font-size: 1.2rem;
}

.bottom-shadow {
  box-shadow: inset 15px -75px 62px -56px #525252;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 110px;
  width: 100%;
  z-index: 0;
}

@media only screen and (max-width: 600px) {
  .adblock:last-child {
    margin-bottom: 50px;
  }
}
