:root {
  --warning-color: #ffcdcd;
  --success-color: #d9e8d9;
}

.green {
  background-color: var(--success-color) !important;
  border: 1px solid #5ecf79 !important;
}

.green:hover {
  background-color: var(--success-color) !important;
}

.red {
  background-color: var(--warning-color) !important;
  border: 1px solid #fd9c9c !important;
}

.red:hover {
  background-color: var(--warning-color) !important;
}

.green .select-answer {
  color: #0c7725;
}
.select-answer {
  margin: 0;
  padding: 0;
}

.non-pointer {
  cursor: default !important;
  pointer-events: none !important;
}

.task-img {
  display: block;
  margin: 0 auto 1rem auto;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.answer {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0.7rem 0.5rem;
  padding: 0.6rem;
  cursor: pointer;
  position: relative;
  z-index: 2;

}

.answer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.answer-stat {
  display: block;
  justify-self: center;
  margin: auto 5px;
}

.answer-stat-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  
}

@media (hover: hover) and (pointer: fine) {
  .answer:hover {
    background-color: #edf1fb;
  }
}

@media (hover: none) {
  .answer:active {
    background-color: #edf1fb;
  }
}

@media screen and (max-width: 600px) {
  .answer {
    margin: 0.58rem 0.1rem;
    padding: 0.58rem;
  }
  .task-img {
    margin: 0 auto 0rem auto;
    padding: 0;
  }
}


