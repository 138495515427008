.achivments-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 0;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.achivment-img {
  width: 60%;
  margin: 0 auto;
  cursor: pointer;
  display: block;
  transform: scale(1);
  transition-duration: 500ms;
}
.achivment-img:hover {
  transform: scale(1.05);
  transition-duration: 250ms;
}

.badge-info {
  position: relative;
  border: 1px solid rgb(233, 233, 233);
  padding: 0.5rem;
  margin: 0 5%;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-end: 5;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  animation: rlease 0.5s;
}

@keyframes rlease {
  from {
    transform: scale(1, 0);
  }
  to {
    transform: scale(1, 1);
  }
}

.badge-info span {
  position: absolute;
  bottom: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.badge-p {
  display: block;
  word-break: break-all;
}

.blocked {
  mask-image: linear-gradient(rgba(0, 0, 0, 0.12), transparent);
}

.achivment-a-tag{
  display: block;
  margin: 15px 0;
  color: blue;
}

.test {
  border: 1px solid red;
  height: 2000px;
  position: fixed;
  top: 0;
  left: 142px;
}
