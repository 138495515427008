.dash-header {
  text-align: center;
  margin: 0.8rem 0;
}

.mode-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 2rem;
  margin: 28px 10px 0 10px;
}

.mode-selector {
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #d3d3d3;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mode-selector-description {
}

.mode-selector-header {
  margin-bottom: 0;
  margin-top: 0.9rem;
}

.mode-selector-extended {
  grid-column-start: 1;
  grid-column-end: 3;
}

.mode-selector:hover {
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.mode-selector a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 660px) {
 #dash-main-header {
    display: none!important;
  }
  .mode-grid {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0 0px;
    grid-gap: 8px;
  }
  .mode-selector {
    margin: 3px 0;
    padding: 5px 15px;
  }
  .mode-selector:hover {
    box-shadow: none;
  }
}
