/* MainStoriesComponent.css */
.story-viewer {
  position: relative;
  height: 90vh;
  margin: auto;
  overflow: hidden;
  background-color: #353535;
  border-radius: 12px;
  overflow-x: hidden;
  overflow: hidden;
}

.story-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  touch-action: pan-x;
  overflow-x: hidden;
  overflow: hidden;
}

.progress-bar-container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  padding: 10px 10px;
}

.progress-bar {
  flex-grow: 1;
  height: 2px;
  /*  background-color: rgb(178, 173, 173); */
  background-color: rgb(252 252 252 / 35%);
  margin: 0 2px;
  box-shadow: 0 0 5px black;
}

.story img {
  object-fit: cover;
}

.progress-bar.active {
  background-color: white; /* Change as needed */
}

.story-viewer-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  font-size: 24px;
  color: white;
  border: none;
  background-color: transparent; /* No background */
  cursor: pointer;
  text-shadow: 0 0 15px black;
  padding: 0;
}

.story-viewer-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.story-viewer-nav-button.left {
  left: 5px;
}

.story-viewer-nav-button.right {
  right: 5px;
}

.story-viewer-nav-button:focus {
  outline: none;
}

.story-viewer-pause-play-button {
  position: absolute;
  right: 50px;
  top: 20px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent; /* No background */
  color: white; /* Adjust as needed */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 15px black;
}

.story-viewer-close-button {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent; /* No background */
  color: white; /* Adjust as needed */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 15px black;
}

.story-viewer-pause-play-button:focus {
  outline: none;
}

.story-viewer-header {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 10px 10px;
  color: white;
  text-shadow: 0 0 15px black;
}

/* .highlights-container {
  margin: -10px 0 10px 0;
  display: inline-flex;
} */

.highlights-container {
  margin: -10px 0 10px 0;
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping of items */
  justify-content: flex-start; /* Align items to the start */
  overflow-x: auto; /* Allow horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
}

.highlights-container::-webkit-scrollbar {
  height: 8px;
}

.highlights-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.highlights-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar itself */
}

.highlights-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar on hover */
}

.highlight {
  position: relative;
  /* padding: 10px; */
  height: 80px;
  width: 90px;
  min-width: 90px;
  font-size: 12px;
  color: rgb(0, 0, 0);
  background-color: #f4f4f4; /* Actual background color */
  border-radius: 8px; /* Rounded corners for the element */
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-shadow: none;
  border: 2px solid transparent;
}

.highlight-skeleton {
  height: 80px;
  width: 90px;
  min-width: 90px;
  font-size: 12px;
  color: rgb(0, 0, 0);
  background-color: #f4f4f4; /* Actual background color */
  border-radius: 8px; /* Rounded corners for the element */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-shadow: none;
  margin: 5px;
  border: 2px solid transparent;
}

@keyframes pulseAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.highlight p {
  margin: 0;
  font-size: 12px;
  color: white;
  z-index: 1;
}
.highlight-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  z-index: 1;
  background: linear-gradient(to top, black, rgba(0, 0, 0, 0) 33%);
}

.highlight img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 6px;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  padding: 2px;
}

.highlight-unseen {
  border-color: #0186fb;
  box-shadow: 0 0 5px rgb(137, 252, 244);
}

.highlight-seen {
  border-color: rgb(204, 204, 204);
}

/* Hover effect */
.highlight:hover {
  background-color: #e0e0e0; /* Highlight on hover, adjust as needed */
  transition: background-color 0.3s;
}

/* Focus effect */
.highlight:focus {
  outline: none;
}

/* Backdrop styling */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  z-index: 101; /* Ensure it sits above other content but below the popup */
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop-story-header {
  position: absolute;
  top: 0;
  left: 10px;
  width: 100%;
  padding: 10px 10px;
  color: white;
}

/* Popup window styling */
.story-viewer-popup {
  transition: transform 0.5s ease-in-out;
  position: fixed;
  z-index: 20;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  box-sizing: border-box;
  overflow: hidden;
  overflow-x: hidden;
}

@media screen and (max-width: 660px) {
  .story-viewer {
    width: 100vw;
    height: 100dvh;
  }

  /*   .highlights-container {
    margin: 19px 0 0 0;
    display: inline-flex;
  } */
  .highlights-container {
    margin: 19px 0 0 0;
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping of items */
    justify-content: flex-start; /* Align items to the start */
    overflow-x: auto; /* Allow horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* For Firefox */
  }
}
@media screen and (max-width: 770px) {
  .backdrop-story-header {
    display: none;
  }
  .story-viewer-nav-button.left:hover,
  .story-viewer-nav-button.right:hover {
    background-color: transparent;
  }
}

@media screen and (min-width: 661px) {
  .story-viewer {
    max-width: 432px;
    min-width: 432px;
    max-height: 900px;
  }
  .story-viewer-nav-button.left,
  .story-viewer-nav-button.right {
    position: fixed; /* Or absolute, depending on your layout */
    top: 50%; /* Adjust as needed */
    transform: translateY(-50%);
  }

  .story-viewer-nav-button.left {
    left: calc(50% - 258px); /* Half the viewer's width */
  }

  .story-viewer-nav-button.right {
    right: calc(50% - 258px); /* Adjust based on your actual layout */
  }
}

.story-viewer-animate-next {
  animation: slideAndFadeOutNext 0.3s forwards;
}

.story-viewer-animate-prev {
  animation: slideAndFadeOutPrev 0.3s forwards;
}

@keyframes slideAndFadeOutNext {
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideAndFadeOutPrev {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
