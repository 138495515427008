.action-story-button {
    position: absolute;
    bottom: 30px;
    left: 50%; /* Center horizontally relative to the parent */
    transform: translateX(-50%); /* Offset by half of its own width */
    color: rgb(0, 0, 0);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
/*     text-shadow: 0 0 15px black; */
    padding: 5px 10px;
    box-shadow: 0 0 17px rgb(176, 176, 176);
  }